import React, {FC} from 'react';
import { Spinner} from "react-bootstrap";

const Loader: FC = () => {
    return (
        <div className="fullScreenSpinner d-flex justify-content-center">
            <div className="my-auto">
                <Spinner animation="border" variant="primary"/>
            </div>
       </div>
    );
};
export default Loader;




