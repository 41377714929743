import { useAuth } from '../config/AuthContext';
import { rolesConfig } from '../config/rolesConfig';

const usePermissions = () => {
    const { userGroups } = useAuth();

    const hasPermission = (action: string) => {
        return userGroups.some((group: string) => rolesConfig[group]?.canPerform.includes(action));
    };

    return { hasPermission };
};

export default usePermissions;
