import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {Container, Row, Col, Form} from 'react-bootstrap';
import {useForm, FormProvider} from 'react-hook-form';
import {useGetCustomConfig} from "../../hooks/slothCustomHooks";
import {ConfiguratorContext} from "../../hooks/configuratorContext";
import {ModuleDifference} from "../../types";
import {usePutPresetConfigMutation, usePutSiteConfigMutation} from "../../redux/api/slothAPI";
import Loader from "../Loader";
import PermissionButton from "../ButtonWithPermission";


import {
    addMetaToInventoryConfig,
    changeInventoryConfig,
    comparePartialModuleConfig,
    contextAddSlot,
    contextDeleteSlot,
    convertFormDataToPartialConfig, createInventoryConfig,
    getMergedConfig, isNotConfigured, saveChanges
} from "../../utils/config-helper";

import {removeObjectsWithEmptyFirstKeyAndNullValue} from "../../utils/utils";
import {TabNav, TabNavItem} from "../../styles/TabNav";
import ModuleConfig from "../configuration/ModuleConfig";
import InventoryConfig from "../inventory/InventoryConfig";


interface IConfigurator {
    location: string;
}

const FormConfigurator: FC<IConfigurator> = ({location}) => {



    const routerParams = useParams();

    const queryArgs: any = {
        id: location === "site" ? routerParams?.siteId : routerParams?.presetId,
    }

    const inventoryLocation = location === "site" ? "inventory" : "preset-inventory"

    const [activeTab, setActiveTab] = useState<string>('site');
    const formRef = useRef<HTMLFormElement>(null);
    const [mergedConfig, setMergedConfig] = useState<any>(undefined);
    const {
        moduleGroups,
        config,
        slots,
        onlyPresetSlots,
        inactiveSlots,
        appliedPresetsConfig,
        isLoading,
        isAppliedPresetsLoading,
    } = useGetCustomConfig(queryArgs.id, location);


    useMemo(() => {
        if (config?.builderConfig && appliedPresetsConfig && location) {
            setMergedConfig(
                getMergedConfig(
                    config.builderConfig,
                    config.moduleConfig,
                    {
                        inventoryConfig: config?.inventoryConfig || {},
                        slots: slots,
                        onlyPresetSlots: onlyPresetSlots,
                        inactiveSlots: inactiveSlots
                    },
                    appliedPresetsConfig,
                    location,
                    config.gbucket
                )
            );
        }
    }, [config, appliedPresetsConfig, location]);
    const handleFormSubmit = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
        }
    };




    const siteId = routerParams?.site
    const methods = useForm(); // Initialize your form





    const onSubmit = (formData: any) => {
    console.log(formData)

    };


    if (isLoading || isAppliedPresetsLoading) {
        return (
            <Loader/>
        )
    }

    return (
        <>
                <FormProvider {...methods}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <h3>{siteId} configuration</h3>
                            </Col>
                            <Col className={"d-flex justify-content-end"}>
                                <PermissionButton variant="success" onClick={handleFormSubmit}>Save Changes</PermissionButton>
                            </Col>
                        </Row>
                        <Row>
                            <Form onSubmit={methods.handleSubmit(onSubmit)} ref={formRef}>
                                <TabNav justify fill variant="tabs">
                                    <TabNavItem>
                                        <a
                                            href="#"
                                            className={activeTab === 'site' ? 'nav-link active' : 'nav-link'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab('site');
                                            }}
                                        >
                                            <i className="bi bi-sliders"></i> Site
                                        </a>
                                    </TabNavItem>
                                    <TabNavItem>
                                        <a
                                            href="#"
                                            className={activeTab === 'inventory' ? 'nav-link active' : 'nav-link'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab('inventory');
                                            }}
                                        >
                                            <i className="bi bi-folder"></i> Inventory
                                        </a>
                                    </TabNavItem>
                                </TabNav>
                                <div style={{ display: activeTab === 'site' ? 'block' : 'none' }}>
                                    module
                                    {/*<ModuleConfig location={location} />*/}
                                </div>
                                <div style={{ display: activeTab === 'inventory' ? 'block' : 'none' }}>
                                    inventory
                                    {/*<InventoryConfig location={location === 'site' ? 'inventory' : 'preset-inventory'} />*/}
                                </div>

                            </Form>
                        </Row>
                    </Container>
                </FormProvider>
        </>
    );
}

export default FormConfigurator;

