import {useEffect, useState} from 'react';
import {
    useGetSiteConfigQuery,
    useGetPresetConfigQuery,
    useGetPresetConfigsQuery
} from 'redux/api/slothAPI';

import {getModuleGroups, mergeInventoryConfig} from "../utils/config-helper";
import {mergeDeep} from "../utils/utils";



export const useGetCustomConfig = (id: string, location: string) => {
    const getQuery = location === "site" ? useGetSiteConfigQuery : useGetPresetConfigQuery;
    const {data, error, isLoading, isError} = getQuery({id: id});

    const {
        data: appliedPresetsConfig,
        isLoading: isAppliedPresetsLoading,
        isError: isAppliedPresetsError,
    } = useGetPresetConfigsQuery(data?.presets);

    const [slots , setSlots] = useState<string[]>([]);
    const [onlyPresetSlots, setOnlyPresetSlots] = useState<string[]>([]);
    const [inactiveSlots, setInactiveSlots] = useState<string[]>([]);
    const [config , setConfig] = useState<any>();
    const moduleGroups = getModuleGroups(data && data.builderConfig.modules);

    useEffect(() => {

        if (data) {

            let fetchedSiteData = structuredClone(data);

            // original site module and inventory Config
            fetchedSiteData.originalInventoryConfig = structuredClone(data.inventoryConfig);
            fetchedSiteData.originalModuleConfig = structuredClone(data.moduleConfig);
            let mergedPresetsInventoryConfig = (appliedPresetsConfig && mergeInventoryConfig(appliedPresetsConfig)) || {}
            let inactive : string[] = [];

            if(data && data.gbucket){
                fetchedSiteData.inventoryConfig = mergeDeep(
                    fetchedSiteData.inventoryConfig,
                    data.gbucket.results[0].inventoryConfig
                )
                fetchedSiteData.moduleConfig = mergeDeep(
                    fetchedSiteData.moduleConfig,
                    data.gbucket.results[0].moduleConfig
                )
            }

            // remove slots that are null
            fetchedSiteData.localInventoryConfig = Object.fromEntries(
                Object.entries(fetchedSiteData?.inventoryConfig || {}).filter(
                    ([key, value]) => value !== null
                )
            );

            // If presets are applied get the slots that are from the presets and not available not on site
            if(appliedPresetsConfig) {
                setOnlyPresetSlots(Object.keys(mergedPresetsInventoryConfig).filter((slot) => !Object.keys(fetchedSiteData.localInventoryConfig).includes(slot)))
                // set inactive slots which are keys in mergedPresetsInventoryConfig but have null value in fetchedSiteData.localInventoryConfig
                inactive = Object.keys(mergedPresetsInventoryConfig).filter((slot) => fetchedSiteData.inventoryConfig[slot] === null)
            }

            //get all the slots that are active
            let allSlots = () => {
                if (appliedPresetsConfig && appliedPresetsConfig.length > 0) {

                    let mergedSlots =  Object.keys(mergeDeep(fetchedSiteData.localInventoryConfig || {} , mergedPresetsInventoryConfig))
                    // return mergedSlots and remove all inactiveSlots
                    return mergedSlots.filter((slot) => !inactive.includes(slot))
                }
                return Object.keys(fetchedSiteData.localInventoryConfig)
            }
            setInactiveSlots(inactive)
            setConfig(fetchedSiteData)
            setSlots(allSlots);
        }
    }, [appliedPresetsConfig, data, location]);
    return {config , moduleGroups   , appliedPresetsConfig ,   slots , onlyPresetSlots , inactiveSlots , error, isLoading, isAppliedPresetsLoading, isError, isAppliedPresetsError};
}









