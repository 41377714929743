import styled from 'styled-components';
import {Nav} from 'react-bootstrap';
import {device} from '../styles/Breakpoints';
import {interFontStyle} from '../styles/Fonts';
import React from "react";

interface ResponsiveNavProps {
    isOpen: boolean;
}

export const TabNav = styled(Nav)`
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ECEEF6;
  margin: 24px 0;
`;

export const TabNavItem = styled(Nav.Item)`
  ${interFontStyle}
  & a.nav-link.active {
    background: #1570EF !important;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px #1018280F;
    box-shadow: 0px 1px 3px 0px #1018281A;
  }

  @media ${device.md} {
    & a i {
      display: block;
      margin: 0 !important
    }
  }

  & a i {
    margin-right: 8px;
  }

  & a, span {
    display: block;
    color: #293056;
    font-size: clamp(12px, 1vw, 14px);
    line-height: 18px;
    padding: 12px 24px 12px 16px;
  }
`;

export const MobileNavIcon = styled(({ isOpen, ...props }: ResponsiveNavProps & React.HTMLAttributes<HTMLDivElement>) => <div {...props} />)`
  ${interFontStyle}
  display: none;
  @media ${device.md} {
    margin: 10px 0 0;
    background: ${({isOpen}) => (isOpen ? '#1570EF' : 'white')};
    color: ${({isOpen}) => (isOpen ? '#fff' : '#000')};;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px #1018280F;
    box-shadow: 0px 1px 3px 0px #1018281A;
    display: flex;
    justify-content: center;
    padding: 12px 24px 12px 16px;
    cursor: pointer;
    font-size: 14px
    align-items: center;
    width: 100%;
  }

  i {
    margin-left: auto;
    margin-right: 8px;
  }
`;

export const ResponsiveNav = styled(({isOpen, ...props}: ResponsiveNavProps & React.HTMLAttributes<HTMLDivElement>) => <div {...props} />)`
  @media ${device.md} {
    display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
    margin-bottom: 20px;
  }
`;

