import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from "./components/Header";
import NoMatch from "./components/NoMatch";
import { useAuth } from './config/AuthContext';
import withAuthorization from './config/withAuthorization';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@aws-amplify/ui-react/styles.css';
import { GlobalStyle } from './styles/GlobalStyle';
import { FontLoader } from './styles/Fonts';
import {FormProvider} from "react-hook-form";
import FormConfigurator from "./components/form-configuration/FormConfigurator";


// Lazy load components
const HomePage = lazy(() => import("./components/home/HomePage"));
const Sites = lazy(() => import("./components/sites/Sites"));
const Site = lazy(() => import("./components/sites/Site"));
const Publishers = lazy(() => import("./components/publishers/Publishers"));
const SiteSettings = lazy(() => import("./components/sites/SiteSettings"));
const TagInTag = lazy(() => import("./components/tags/TagInTag"));
const Tags = lazy(() => import("./components/tags/Tags"));
const InventoryConfig = lazy(() => import("./components/inventory/InventoryConfig"));
const Presets = lazy(() => import("./components/presets/Presets"));
const Preset = lazy(() => import("./components/presets/Preset"));

const PresetSelector = lazy(() => import("./components/presets/PresetSelector"));
const Configurator = lazy(() => import("./components/configuration/Configurator"));
const ModuleConfig = lazy(() => import("./components/configuration/ModuleConfig"));
const PresetSettings = lazy(() => import("./components/presets/PresetSettings"));
const Statistics = lazy(() => import("./components/statistics/Stats"));

function App() {
    const { userGroups, userName, handleSignOut } = useAuth();


    // const cssUrl = useEnvConfig("CSS_URL");
    // // Load the CSS file from the environment configuration
    // if (cssUrl && typeof cssUrl === 'string') {
    //     const cssLink = document.createElement('link');
    //     cssLink.rel = 'stylesheet';
    //     cssLink.href = cssUrl;
    //     console.log("css loaded")
    // }

    // Create protected components
    const protectedComponents = {
        ProtectedHome: withAuthorization(HomePage, userGroups),
        ProtectedSites: withAuthorization(Sites, userGroups),
        ProtectedPresets: withAuthorization(Presets, userGroups),
        ProtectedPreset: withAuthorization(Preset, userGroups),
        ProtectedPublishers: withAuthorization(Publishers, userGroups),
        ProtectedSite: withAuthorization(Site, userGroups),
        ProtectedSiteSettings: withAuthorization(SiteSettings, userGroups),
        ProtectedTags: withAuthorization(Tags, userGroups),
        ProtectedInventoryConfig: withAuthorization(InventoryConfig, userGroups),
        ProtectedPresetSelector: withAuthorization(PresetSelector, userGroups),
        ProtectedConfigurator: withAuthorization(Configurator, userGroups),
        ProtectedModuleConfig: withAuthorization(ModuleConfig, userGroups),
        ProtectedPresetSettings: withAuthorization(PresetSettings, userGroups),
        ProtectedStatistics: withAuthorization(Statistics, userGroups),
        ProtectedTagInTag: withAuthorization(TagInTag, userGroups),
    };

    return (
        <>
            <FontLoader />
            <GlobalStyle />
            <Header userName={userName} signOut={handleSignOut} />
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<protectedComponents.ProtectedHome />} />
                    <Route path="sites" element={<protectedComponents.ProtectedSites />} />
                    <Route path="presets" element={<protectedComponents.ProtectedPresets />} />
                    <Route path="publishers" element={<protectedComponents.ProtectedPublishers />} />
                    <Route path="presets/:presetId" element={<protectedComponents.ProtectedPreset />}>
                        <Route index element={<protectedComponents.ProtectedConfigurator location="preset" />} />
                        <Route path="settings" element={<protectedComponents.ProtectedPresetSettings />} />
                        <Route path="configurator" element={<protectedComponents.ProtectedConfigurator location="preset" />} />
                    </Route>
                    <Route path="site/:siteId" element={<protectedComponents.ProtectedSite />}>
                        <Route index element={<Configurator location="site" />} />
                        <Route path="configurator" element={<Configurator location="site" />} />
                        <Route path="form-configurator" element={<FormConfigurator location="site" />} />
                        <Route path="presets" element={<PresetSelector />} />
                        <Route path="stats" element={<Statistics />} />
                        <Route path="settings" element={<SiteSettings />} />
                        <Route path="tags" element={<Tags />}/>

                        <Route path="*" element={<NoMatch errorMessage="This Page Doesn't Exist" />} />
                    </Route>
                    <Route path="*" element={<NoMatch errorMessage="This Page Doesn't Exist" />} />
                    {/*<Route path="environment.json" */}
                </Routes>
            </Suspense>
        </>
    );
}


export default App;