import React from 'react';
import { Button } from '../styles/Buttons';
import usePermissions from '../hooks/usePermissions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface PermissionButtonProps {
    [x: string]: any;
}

const PermissionButton = ({ variant, ...props }: PermissionButtonProps) => {
    const { hasPermission } = usePermissions();
    const canPerformAction = hasPermission('edit');

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            You do not have permission to perform this action.
        </Tooltip>
    );

    const button = (
        <span className="d-inline-block" style={{ cursor: !canPerformAction ? 'not-allowed' : 'pointer' }}>
            <Button
                {...props}
                variant={variant}
                disabled={!canPerformAction}
                style={{
                    pointerEvents: !canPerformAction ? 'none' : 'auto',
                }}
            />
        </span>
    );

    return (
        !canPerformAction ? (
            <OverlayTrigger
                placement="top"
                overlay={renderTooltip}
                delay={{ show: 150, hide: 150 }}
            >
                {button}
            </OverlayTrigger>
        ) : (
            button
        )
    );
};

export default PermissionButton;
