import React, {createContext, useContext, useState, useEffect, ReactNode, useCallback} from 'react';
import {Auth, Amplify} from 'aws-amplify';
import {useDispatch} from 'react-redux';
import {addRole} from '../redux/features/userRoleSlice';
import {useLocation} from 'react-router-dom';
import NoMatch from '../components/NoMatch';
import OktaLogin from '../components/Login/OktaLogin';
import {extractNameFromEmail} from '../utils/utils';
import {ensureConfigLoaded} from '../redux/store';

import authConfig from './auth';

interface AuthContextProps {
    user: any;
    userGroups: string[];
    userName: string;
    handleSignOut: () => Promise<void>;
    loading: boolean;
}

const AuthContext = createContext<AuthContextProps | null>(null);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider = ({children}: AuthProviderProps) => {
    const [user, setUser] = useState<any>(null);
    const [userGroups, setUserGroups] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [amplifyConfigured, setAmplifyConfigured] = useState(false);
    const [showAccessError, setShowAccessError] = useState(false);

    const dispatch = useDispatch();

    const clearBrowserStorage = useCallback(() => {
        localStorage.clear();
        sessionStorage.clear();
        document.cookie.split(';').forEach((c) => {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });
    }, []);

    const getUserInfo = useCallback(async () => {
        try {
            const userInfo = await Auth.currentAuthenticatedUser();
            const idToken = userInfo.signInUserSession.idToken.jwtToken;
            const payload = idToken.split('.')[1];
            const decodedPayload = JSON.parse(window.atob(payload));
            const userGroups = decodedPayload['cognito:groups'] || [];
            const newUser = {email: decodedPayload.email};

            setUser(newUser);
            setUserGroups(userGroups);

            if (!userGroups.includes('admins')) {
                setShowAccessError(true);
                clearBrowserStorage();
                setUser(null);
                return;
            }

            const filteredGroups = userGroups.filter((item: string) => !item.includes("Okta"));
            const result = filteredGroups.length > 0 ? filteredGroups[0] : null;
            if (result) {
                dispatch(addRole(result));
            }
        } catch (err) {
            console.error('Error in getUserInfo:', err);
            setUser(null);
            setUserGroups([]);
            throw err;
        }
    }, [dispatch, clearBrowserStorage]);

    useEffect(() => {
        const initializeAuth = async () => {
            setLoading(true);
            try {
                await ensureConfigLoaded();
                const config = await authConfig();
                Amplify.configure(config);
                setAmplifyConfigured(true);
                await getUserInfo();
            } catch (error) {
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        initializeAuth();
    }, [getUserInfo]);


    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            setUser(null);
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    const userName = user ? extractNameFromEmail(user.email) : "";


    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <OktaLogin/>;
    }

    if (showAccessError) {
        return <NoMatch errorMessage="Access Denied."/>;
    }

    return (
        <AuthContext.Provider value={{user, userGroups, userName, handleSignOut, loading}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
